import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../../images/provendfinal.png";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";

import { withRouter } from 'react-router-dom'
import { Link, animateScroll as scroll } from "react-scroll";

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;
const Divider = tw.div`my-16 border-b-2 border-gray-300 w-full`;

export default () => {
  const ButtonEx = withRouter(({ history }) => (
    <Link
      onClick={() => { history.push('/parceiros') }}
    >
      Parceiros
    </Link>
  ));
  const ButtonExPP = withRouter(({ history }) => (
    <Link
      onClick={() => { history.push('/privacypolicy') }}
    >
      Política de Privacidade
    </Link>
  ));
  return (
    <Container id="footer_id">
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} style={{width: "30%"}}/>
            {/* <LogoText>Treact Inc.</LogoText> */}
          </LogoContainer>
          <CompanyDescription style={{fontSize:20}}>
          A nossa proximidade... <br />faz a diferença!!!
          </CompanyDescription>
          {/* <SocialLinksContainer>
            <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer> */}
        </WideColumn>
        <Column>
          <ColumnHeading style={{fontSize: 20}}>Informações de contacto               </ColumnHeading>
          <LinkList>
            <LinkListItem>
              (+351) 932 596 113
            </LinkListItem>
            <LinkListItem>
              (+351) 914 041 557
            </LinkListItem>
            <LinkListItem>
              (+351) 960 291 557
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <br />
          <LinkList >
            <LinkListItem style={{with: 200}}>
              Largo 1º de Maio , nº5, Cabrela <span>&nbsp;  &nbsp;</span>   <span>&nbsp;  &nbsp;</span> <span>&nbsp;  &nbsp;</span> <span>&nbsp;  &nbsp;</span> <span>&nbsp;  &nbsp;</span> <span>&nbsp;  &nbsp;</span><span>&nbsp;  &nbsp;</span> <span>&nbsp;  &nbsp;</span> <span>&nbsp;  &nbsp;</span> <span>&nbsp;  &nbsp;</span> <span>&nbsp;  &nbsp;</span> <span>&nbsp;  &nbsp;</span> <span>&nbsp;  &nbsp;</span> <span>&nbsp;  &nbsp;</span> <span>&nbsp;  &nbsp;</span> <span>&nbsp;  &nbsp;</span> <span>&nbsp;  &nbsp;</span> <span>&nbsp;  &nbsp;</span> <span>&nbsp;  &nbsp;</span> <span>&nbsp;  &nbsp;</span>                
            </LinkListItem>
            <LinkListItem>
              provend.pt@gmail.com
            </LinkListItem>
            <LinkListItem>
              www.provend.pt
            </LinkListItem>
            <LinkListItem>
              <ButtonExPP/>
            </LinkListItem> 
          </LinkList>
          <br />
        </Column>
        
        <Column>
          <ColumnHeading style={{fontSize: 20}}>Atalhos</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link to="headerDiv"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}>Inicio</Link> 
            </LinkListItem>
            <LinkListItem>
              <Link to="servicosDiv"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}>Serviços</Link> 
            </LinkListItem>
            <LinkListItem>
              <Link to="equipamentosDiv"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}>Equipamentos</Link> 
            </LinkListItem>
            <LinkListItem>
              <ButtonEx />
            </LinkListItem>
          </LinkList>
        </Column>
      </FiveColumns>
      <Divider style={{marginTop: -30}}/>
      <CopywrightNotice style={{paddingBottom: 30, marginTop: -30}}> <span style={{color: "#c50505"}}>&copy; 2024 Provend.</span> Todos os direitos reservados.</CopywrightNotice>
    </Container>
  );
};
